<template>
    <div class="container">
        <FilterDashboard @filter="onFilter" @filterStart="onFilterStart" @filterEnd="onFilterEnd"
            @filterStatus="onFilterStatus" @filterNivel="onFilterNivel" :showNivel="true" />


        <div style="text-align:right" class="chart">
            <div class="row">
                <div class="col-md-6">
                    <h5>Vacantes por Responsables</h5>
                    <h6>Total de vacantes: {{ total }}</h6>
                </div>
                <div class="col-md-3">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button :class="{ active: activeBar }" type="button" class="btn btn-primary"
                            @click="changeChart('bar')">Barras</button>
                        <button :class="{ active: activePie }" type="button" class="btn btn-primary"
                            @click="changeChart('donut')">Pastel</button>
                    </div>
                </div>
            </div>

        </div>

        <div style="display: flex; justify-content: center; align-items: center;">
            <div v-if="options.chart.type === 'bar'">
                <apexchart ref="chart" width="700" type="bar" :options="{ ...options }" :series="series"></apexchart>
            </div>
            <div v-else-if="options.chart.type === 'donut'">
                <apexchart ref="chart" width="700" type="donut" :options="pieOptions" :series="pieSeries"> </apexchart>
            </div>
        </div>

    </div>
</template>

<script>
import { CompanyService } from "@/services";
import VueApexCharts from 'vue3-apexcharts';
import FilterDashboard from "../filter/FilterDashboard.vue";

export default ({
    name: 'vacantes-responsable',
    components: { apexchart: VueApexCharts, FilterDashboard },
    data() {
        return {
            activeBar: true,
            activePie: false,
            options: {
                chart: {
                    id: 'vuechart-responsable',
                    type: 'bar'
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'Vacantes'
                    }
                },
                fill: {
                    opacity: 1,
                    //colors: ['#B22222', '#A9A9A9']
                },
                theme: {
                    palette: 'palette10'
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '',
                        distributed: false,
                        colors: {
                            ranges: [{
                                from: 0,
                                to: 0,
                                colors: []
                            }],
                            backgroundBarColors: [],
                            backgroundBarOpacity: 1,
                            backgroundBarRadius: 0,
                        },
                        dataLabels: {
                            position: 'top',
                            maxItems: 10,
                            total: {
                                enabled: true,
                                formatter: undefined,
                                offsetX: 0,
                                offsetY: 0,
                                style: {
                                    color: '#373d3f',
                                    fontSize: '12px',
                                    fontFamily: undefined,
                                    fontWeight: 600
                                }
                            }
                        }
                    }
                }
            },
            series: [{
                name: 'vacante',
                data: []
            }],
            pieSeries: [],
            pieOptions: {
                chart: {
                    type: 'donut',
                },

                labels: [],
            },
            user: JSON.parse(localStorage.getItem("user")),
            niveles: [],
            filtered: {
                filterStart: "",
                filterEnd: "",
                filterStatus: "",
                filterNivel: "",
                userId: ""
            },
            total: 0
        }
    },
    mounted() {
        this.filtered.userId = this.user._id
        this.getVacantesByResponsableAndUserId(this.user._id);
    },
    methods: {
        async getVacantesByResponsableAndUserId(userId) {

            try {
                this.total = 0;
                const response = await CompanyService.getVacantesByResponsableAndUserId(userId);
                const { data } = response.data;

                data.map((item) => {
                    this.options.xaxis.categories.push(item.responsable);
                    this.pieOptions.labels.push(item.responsable);
                });

                data.map((item) => {
                    this.total += item.vacantes;
                    this.series[0].data.push(item.vacantes);
                    this.pieSeries.push(item.vacantes);
                });

                let countColumns = this.options.xaxis.categories.length;
                var optimalColumnWidthPercent = 15 + (60 / (1 + 30 * Math.exp(-countColumns / 3)));


                this.options.plotOptions.bar = {
                    ...this.options.plotOptions.bar,
                    columnWidth: (optimalColumnWidthPercent + '%').toString()
                };


                this.$refs.chart.updateOptions(this.options)

            } catch (e) {
                console.log(e);
            }
        },

        async getVacantesByResponsableFilter() {

            try {
                this.total = 0;
                const response = await CompanyService.getVacantesByResponsableFilter(this.filtered);
                const { data } = response.data;

                this.options.xaxis = { ... this.options.xaxis, categories: [] };
                this.series[0] = { ...this.series[0], data: [] };

                this.pieOptions = { ...this.pieOptions, labels: [] };
                this.pieSeries = [];


                data.map((item) => {
                    this.options.xaxis.categories.push(item.responsable);
                    this.pieOptions.labels.push(item.responsable);
                });

                data.map((item) => {
                    this.total += item.vacantes;
                    this.series[0].data.push(item.vacantes);
                    this.pieSeries.push(item.vacantes);
                });

                this.$refs.chart.updateOptions(this.options)

            } catch (e) {
                console.log(e);
            }
        },
        onFilter(filter) {
            console.log('filter =>', filter)
            this.filtered.filter = filter
            this.getVacantesByResponsableFilter();
        },
        onFilterStart(filter) {
            console.log('filterDate =>', filter)
            this.filtered.filterStart = filter
        },
        onFilterEnd(filter) {
            console.log('filterDate =>', filter)
            this.filtered.filterEnd = filter
            this.getVacantesByResponsableFilter();
        },
        onFilterStatus(filter) {
            console.log('filterStatus=>', filter)
            this.filtered.filterStatus = filter
            this.getVacantesByResponsableFilter();
        },
        onFilterNivel(filter) {
            console.log('filterNivel=>', filter);
            this.filtered.filterNivel = filter;
            this.getVacantesByResponsableFilter();
        },
        onClearFilter(filter) {
            console.log('clear=>', filter)
            if (filter) {

                this.options.xaxis = { ... this.options.xaxis, categories: [] };
                this.series[0] = { ...this.series[0], data: [] };

                this.pieOptions.labels = [];
                this.pieSeries = [];

                this.getVacantesByResponsableAndUserId(this.user._id);

                // enviar el componente que limpie los campos
                //ponga en false el valor de clearFilter
            }

        },
        changeChart(type) {
            this.options.chart.type = type;
            this.toggleActive();

        },
        toggleActive() {
            this.activeBar = !this.activeBar;
            this.activePie = !this.activePie;
        }

    }
})
</script>

<style>
.chart {
    padding-top: 3%;
}
</style>